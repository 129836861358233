import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import del from '../../assets/delete.svg';
import plus from '../../assets/plus.svg';
import {backendUrl} from '../../constant/AppConstant';
import {debounce} from 'lodash';

const CourseSeminarians = ({selectedSupervisorId, selectedAssistants, onSelectionChange}) => {
  const [seminarians, setSeminarians] = useState(['']);
  const [inputValues, setInputValues] = useState(['']);
  const [showOptions, setShowOptions] = useState([]);
  const dropdownRefs = useRef([]);
  const [filteredSeminarians, setFilteredSeminarians] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [totalSeminarians, setTotalSeminarians] = useState([]);
  const [noMoreSeminariansMessage, setNoMoreSeminariansMessage] = useState(false);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(seminarians.filter((s) => s !== ''));
    }
  }, [seminarians, onSelectionChange]);

  const fetchSeminarians = useCallback(async (searchString = '') => {
    try {
      const response = await fetch(`${backendUrl}/teacher/getSeminarianList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({searchString}),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        const filterData = data.result.teachers.filter(
            (teacher) =>
              teacher.id !== selectedSupervisorId &&
            !selectedAssistants.includes(teacher.id)
        );
        setFilteredSeminarians(filterData);
        setHasFetched(true);
        if (!searchString) {
          setTotalSeminarians(filterData);
        }
      } else {
        console.log('Error while getting list of seminarians:', response.statusText);
      }
    } catch (error) {
      console.log('Error executing request:', error);
    }
  }, [selectedSupervisorId, selectedAssistants]);

  // eslint-disable-next-line
  const debouncedFetchSeminarians = useCallback(
      debounce((searchString) => {
        fetchSeminarians(searchString);
      }, 800),
      [fetchSeminarians]
  );

  useEffect(() => {
    fetchSeminarians();
    // eslint-disable-next-line
  }, [selectedSupervisorId, selectedAssistants]);

  const remainingSeminarians = useMemo(
      () =>
        totalSeminarians.filter(
            (seminarian) =>
              !seminarians.includes(seminarian.id) &&
              seminarian.id !== selectedSupervisorId &&
              !selectedAssistants.includes(seminarian.id)
        ), [totalSeminarians, selectedSupervisorId, seminarians, selectedAssistants]
  );

  const handleSeminarianChange = (index, event) => {
    const value = event.target.value;
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = value;
    setInputValues(updatedInputValues);

    const updatedShowOptions = [...showOptions];
    updatedShowOptions[index] = true;
    setShowOptions(updatedShowOptions);

    if (value.trim()) {
      debouncedFetchSeminarians(value);
    } else {
      fetchSeminarians();
    }
  };

  useEffect(() => {
    if (remainingSeminarians.length > 0) {
      setNoMoreSeminariansMessage(false);
    }
  }, [remainingSeminarians.length]);

  const handleSeminarianFocus = (index) => {
    setShowOptions((prev) => {
      const updated = [...prev];
      updated[index] = true;
      return updated;
    });

    if (!inputValues[index].trim()) {
      fetchSeminarians();
    }
  };

  const handleOptionClick = (index, selectedSeminarian) => {
    const updatedSeminarians = [...seminarians];
    updatedSeminarians[index] = selectedSeminarian.id;
    setSeminarians(updatedSeminarians);

    const updatedInputValues = [...inputValues];
    const patronymic = selectedSeminarian.patronymic || '';
    updatedInputValues[index] =
      `${selectedSeminarian.lastName} ${selectedSeminarian.firstName} ${patronymic}\n${selectedSeminarian.email}`;
    setInputValues(updatedInputValues);

    const updatedShowOptions = [...showOptions];
    updatedShowOptions[index] = false;
    setShowOptions(updatedShowOptions);
    fetchSeminarians();
  };

  const addSeminarian = () => {
    if (remainingSeminarians.length === 0) {
      setNoMoreSeminariansMessage(true);
      return;
    }
    if (seminarians.some((assistant) => assistant === '')) {
      return;
    }
    setSeminarians([...seminarians, '']);
    setInputValues([...inputValues, '']);
    setShowOptions([...showOptions, false]);
    setNoMoreSeminariansMessage(false);
  };

  const removeSeminarian = (index) => {
    const updatedSeminarians = [...seminarians];
    const updatedInputValues = [...inputValues];
    const updatedShowOptions = [...showOptions];

    updatedSeminarians.splice(index, 1);
    updatedInputValues.splice(index, 1);
    updatedShowOptions.splice(index, 1);

    if (updatedSeminarians.length === 0) {
      setSeminarians(['']);
      setInputValues(['']);
      setShowOptions([false]);
    } else {
      setSeminarians(updatedSeminarians);
      setInputValues(updatedInputValues);
      setShowOptions(updatedShowOptions);
    }
    setNoMoreSeminariansMessage(false);
  };

  const handleClickOutside = useCallback((event) => {
    dropdownRefs.current.forEach((dropdownRef, index) => {
      if (dropdownRef && !dropdownRef.contains(event.target)) {
        setShowOptions((prev) => {
          const updatedShowOptions = [...prev];
          updatedShowOptions[index] = false;
          return updatedShowOptions;
        });
      }
    });
  }, []);

  const filteredOptions = (index) => {
    return filteredSeminarians.filter(
        // eslint-disable-next-line
        (s) => !seminarians.includes(s.id) || seminarians[index] === s.id &&
          !selectedAssistants.includes(s.id)
    );
  };

  useEffect(() => {
    const handleMousedown = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener('mousedown', handleMousedown);
    return () => {
      document.removeEventListener('mousedown', handleMousedown);
    };
    // eslint-disable-next-line
  }, [showOptions]);

  return (
    <div className="form-field">
      <div className="d-flex align-items-center">
        <h4>Семинаристы курса</h4>
      </div>
      {seminarians.map((seminarian, index) => (
        <div key={index} className="d-flex align-items-center">
          <div className="dropdown" ref={(el) => (dropdownRefs.current[index] = el)}>
            <textarea
              value={inputValues[index]}
              onFocus={() => handleSeminarianFocus(index)}
              onChange={(e) => handleSeminarianChange(index, e)}
              className="form-input form-select form-visitor"
              placeholder="Начните вводить ФИО или email"
              style={{
                whiteSpace: 'pre-line',
                resize: 'none',
                height: inputValues[index] ? '45px' : '30px',
              }}
            />
            {showOptions[index] && (
              <div className="dropdown-menu">
                {filteredOptions(index).length > 0 ? (
                  filteredOptions(index).map((option) => (
                    <div
                      key={option.id}
                      className="dropdown-item"
                      onClick={() => handleOptionClick(index, option)}
                    >
                      <span>{option.lastName} {option.firstName} {option.patronymic || ''}</span>
                      <span style={{display: 'block', color: 'gray', fontSize: '12px'}}>
                        {option.email}
                      </span>
                    </div>
                  ))
                ) : (
                  hasFetched && <div className="dropdown-item">Ничего не найдено</div>
                )}
              </div>
            )}
          </div>
          {(seminarians.length > 1 || seminarians[index]) && (
            <button
              type="button"
              className="delete name-delete"
              onClick={() => removeSeminarian(index)}
            >
              <img src={del} alt="Удалить" />
            </button>
          )}
        </div>
      ))}
      {noMoreSeminariansMessage && (
        <p style={{color: '#727272', margin: '10px 0', fontSize: '14px'}}>Больше нет семинаристов для выбора</p>
      )}
      <button
        type="button"
        className="form-plus"
        onClick={addSeminarian}
      >
        <img src={plus} alt="Plus"/>
      </button>
    </div>
  );
};

export default CourseSeminarians;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment {
    margin: 25px 30px 0 30px;
}

.attachment .delete {
    margin-left: 0px;
}

.attachmentUpload {
    width: 100%;
}

.attachmentUpload .error-message span {
    color: #EA0808;
    font-size: 12px;
    margin-top: 10px;
    font-weight: 400;
}

.error-ci span {
    margin-right: auto;
}

.error-docker {
    margin-left: auto;
}

.uploadedFile img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.attachment__right {
    margin-left: auto;
}

.attachment .success-upload {
    color: green;
}

.attachment__left span, .attachment__right span {
    color: #0A142F;
    font-weight: 600;
}

.attachment__img {
    margin: 0 8px 0 8px;
}

.uploadButton {
    background-color: #0A142F;
    border: none;
    border-radius: 4px;
    width: 33px;
    height: 33px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
    white-space: nowrap;
}

`, "",{"version":3,"sources":["webpack://./src/components/attachmentUpload/AttachmentUpload.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,mBAAmB;AACvB","sourcesContent":[".attachment {\n    margin: 25px 30px 0 30px;\n}\n\n.attachment .delete {\n    margin-left: 0px;\n}\n\n.attachmentUpload {\n    width: 100%;\n}\n\n.attachmentUpload .error-message span {\n    color: #EA0808;\n    font-size: 12px;\n    margin-top: 10px;\n    font-weight: 400;\n}\n\n.error-ci span {\n    margin-right: auto;\n}\n\n.error-docker {\n    margin-left: auto;\n}\n\n.uploadedFile img {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.attachment__right {\n    margin-left: auto;\n}\n\n.attachment .success-upload {\n    color: green;\n}\n\n.attachment__left span, .attachment__right span {\n    color: #0A142F;\n    font-weight: 600;\n}\n\n.attachment__img {\n    margin: 0 8px 0 8px;\n}\n\n.uploadButton {\n    background-color: #0A142F;\n    border: none;\n    border-radius: 4px;\n    width: 33px;\n    height: 33px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    max-width: none;\n    white-space: nowrap;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

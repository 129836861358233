const frontendRootUrl = process.env.REACT_APP_FRONTEND_ROOT_URL || 'localhost:3000';
const backendRootUrl = process.env.REACT_APP_BACKEND_ROOT_URL || 'localhost:8080';
const protocol = process.env.REACT_APP_PROTOCOL || 'http';
const environment = process.env.REACT_APP_ENV || 'local';
const exampleTableUrl = process.env.REACT_APP_EXAMPLE_SPREADSHEET_URL ||
  'https://docs.google.com/spreadsheets/d/17-geT2IqCDHuWqUBOg9IYjIQiNnaL8z9xPPR2qqR6z0/edit?usp=sharing';

export const frontendUrl = `${protocol}://${frontendRootUrl}`;
export const backendUrl = `${protocol}://${backendRootUrl}`;
export const env = environment;
export const tableExample = exampleTableUrl;

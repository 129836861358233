import './app.css';

import React, {useContext, useState} from 'react';
import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainPage from './pages/main/MainPage';
import Login from './pages/login/Login';
import SuccessPage from './pages/success/SuccessPage';
import {AuthContext, AuthProvider} from './context/AuthContext';
import ConfigPage from './pages/configPage/ConfigPage';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import PrivateRoute from './components/privateRoute/privateRoute';
import CourseRegistration from './pages/registration/CourseRegistration';
import ErrorPage from './pages/errorPage/ErrorPage';

/**
 * The main App component for the React app.
 * @return {JSX.Element} The rendered App component.
 */
function App() {
  const [fullName, setFullName] = useState('');

  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <Header/>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<MainPage/>}/>
            <Route path="/login" element={<LoginRoute/>}/>
            <Route
              path="/course/register"
              element={
                <PrivateRoute allowedRoles={['LECTURER', 'ADMIN']}>
                  <CourseRegistration setFullName={setFullName}/>
                </PrivateRoute>
              }
            />
            <Route
              path="/course/register/success"
              element={
                <PrivateRoute allowedRoles={['LECTURER', 'ADMIN']}>
                  <SuccessPage fullName={fullName}/>
                </PrivateRoute>
              }
            />
            <Route
              path="/config/download"
              element={
                <PrivateRoute allowedRoles={['ADMIN']}>
                  <ConfigPage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={
              <ErrorPage errorCode={404} message={
                <>
                Кажется что-то пошло не так!
                Страница, которую вы запрашиваете, не существует. Возможно она <br/>
                устарела, была удалена, или был введен неверный адрес в адресной строке.
                </>
              } />} />
          </Routes>
          <Footer/>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

const LoginRoute = () => {
  const {userData} = useContext(AuthContext);
  if (userData.firstName !== '') {
    return <Navigate to="/" />;
  }

  return <Login/>;
};

export default App;

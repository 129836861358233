import {React, useRef, useCallback, useEffect, useMemo, useState} from 'react';

import edit from '../../assets/edit.svg';
import del from '../../assets/delete.svg';
import plus from '../../assets/plus.svg';
import preview from '../../assets/preview.svg';
import './courseRegistration.css';
import Modal from '../../modal/ModalHomework';
import TextField from '../../components/textField/textField';
import {validator} from '../../utils/validator';
import {backendUrl, tableExample} from '../../constant/AppConstant';
import AttachmentUpload from '../../components/attachmentUpload/AttachmentUpload';
import CourseSeminarians from '../../components/courseSeminarians/CourseSeminarians';
import CourseAssistants from '../../components/courseAssistants/CourseAssistants';
import CourseSupervisors from '../../components/courseSupervisors/CourseSupervisors';
import {useNavigate} from 'react-router-dom';
import CustomSelect from '../../components/select/customSelect';

const CourseRegistration = ({setFullName}) => {
  const [touchedFields] = useState({});
  const [modalActive, setModalActive] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [previewMode, setPreviewMode] = useState(null);
  const navigate = useNavigate();
  const [seminarianIds, setSeminarianIds] = useState([]);
  const [assistantIds, setAssistantIds] = useState([]);
  const [tasksError, setTasksError] = useState('');
  const [supervisorError, setSupervisorError] = useState('');
  const [showShortName, setShowShortName] = useState(false);

  const nameRef = useRef(null);
  const shortNameRef = useRef(null);
  const supervisorRef = useRef(null);
  const spreadsheetUrlRef = useRef(null);
  const serversRef = useRef(null);
  const tasksRef = useRef(null);
  const yearRef = useRef(null);
  const semesterRef = useRef(null);

  const [homeworks, setHomeworks] = useState([]);
  const handleAddTask = (taskData) => {
    setHomeworks((prevTasks) => [...prevTasks, {...taskData, id: Date.now()}]);
  };

  const handleDeleteTask = (taskId) => {
    setHomeworks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };
  const handleEditTask = (taskId) => {
    const taskIndex = homeworks.findIndex((task) => task.id === taskId);
    setEditIndex(taskIndex);
    setModalActive(true);
  };

  const handleSaveTask = (index, updatedHomework) => {
    setHomeworks((prevHomeworks) =>
      prevHomeworks.map((hw, i) => (i === index ? {
        ...prevHomeworks[i],
        ...updatedHomework,
      } : hw))
    );
    setModalActive(false);
    setEditIndex(null);
  };

  const handlePreviewTask = (taskId) => {
    const taskIndex = homeworks.findIndex((task) => task.id === taskId);
    setEditIndex(taskIndex);
    setModalActive(true);
    setPreviewMode(true);
  };

  const closeModal = () => {
    setEditIndex(null);
    setModalActive(false);
    setPreviewMode(false);
  };

  const [selectedSupervisorId, setSelectedSupervisorId] = useState(null);
  const [data, setData] = useState({
    name: '',
    shortName: '',
    year: '',
    semester: '',
    isAutumn: null,
    spreadsheetUrl: '',
    sheetId: '',
    studentListSheetNum: 0,
    reviewQueueSheetNum: 0,
    headId: selectedSupervisorId,
    defaultCiFileId: '',
    defaultDockerfileId: '',
    ciFileId: null,
    ciFileName: null,
    dockerfileId: null,
    dockerFileName: null,
    id: '',
    table: '',
  });

  const [sheetUrlToReviewQueue, setSheetUrlToReviewQueue] = useState({
    sheetUrl: '',
    sheetId: '',
    reviewQueueSheetNum: 0,
  });

  const errorMessageForSheetUrl = (name) => {
    if (data.spreadsheetUrl && sheetUrlToReviewQueue.sheetUrl) {
      let errorMessage = '';
      if (data.sheetId !== sheetUrlToReviewQueue.sheetId) {
        errorMessage = 'Ссылки указывают на разные таблицы.';
      } else if (data.studentListSheetNum === sheetUrlToReviewQueue.reviewQueueSheetNum) {
        errorMessage = 'Ссылки указывают на один и тот же лист.';
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));
    }
  };

  const [errors, setErrors] = useState({});
  const handleChange = ({target}) => {
    const {name, value} = target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это поле обязательно для заполнения.',
      }));
    } else {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
    if (name === 'spreadsheetUrl' || name === 'sheetUrlToReviewQueue') {
      errorMessageForSheetUrl(name);
    }
  };

  const validatorConfig = useMemo(() => ({
    name: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isFormatValid: {
        message:
          'Допустимы только буквы, цифры, пробелы и символы: `-`, `:`, `;`, `_`, `+`, `,`, `.`.',
      },
      maxLength: {
        value: 255,
        message: 'Максимальная длина поля — 255 символов.',
      },
    },
    shortName: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isIdValid: {
        message: 'Допустимы только латинские буквы, цифры, и символы: `.`, `-`, `_`.',
      },
      maxLength: {
        value: 100,
        message: 'Максимальная длина поля — 100 символов.',
      },
    },
    semester: {
      isRequired: {
        message: 'Выберите семестр',
      },
    },
    year: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      maxLength: {
        value: 4,
        message: 'Ожидается год в формате ГГГГ',
      },
      minLength: {
        value: 4,
        message: 'Ожидается год в формате ГГГГ',
      },
    },
    spreadsheetUrl: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isTableValid: {
        message:
          'Приложите ссылку на Google таблицу. Ссылка должна начинаться на `https://docs.google.com/spreadsheets/`.',
      },
      isNotEqualToDefault: {
        defaultValue: 'https://docs.google.com/spreadsheets/',
        message: 'Некорректная ссылка на Google таблицу.',
      },
      maxLength: {
        value: 1024,
        message: 'Максимальная длина поля — 1024 символа.',
      },
    },
    sheetUrlToReviewQueue: {
      isRequired: {
        message: 'Это поле обязательно для заполнения.',
      },
      isTableValid: {
        message:
          'Приложите ссылку на Google таблицу. Ссылка должна начинаться на `https://docs.google.com/spreadsheets/`.',
      },
      isNotEqualToDefault: {
        defaultValue: 'https://docs.google.com/spreadsheets/',
        message: 'Некорректная ссылка на Google таблицу.',
      },
      maxLength: {
        value: 1024,
        message: 'Максимальная длина поля — 1024 символа.',
      },
    },
    branchesWithoutTests: {
      isBranchNumberValid: {
        message: 'Номера веток должны быть положительными целыми числами, разделенными запятыми',
        taskCount: 100,
      },
    },
  }), []);

  const validate = useCallback((dataToValidate = data) => {
    const validationErrors = {};

    Object.keys(validatorConfig).forEach((field) => {
      const fieldConfig = validatorConfig[field];
      const fieldData = dataToValidate[field];

      if (fieldConfig.isRequired && (!fieldData || fieldData.trim() === '')) {
        validationErrors[field] = fieldConfig.isRequired.message;
      } else if (fieldData) {
        const fieldErrors = validator({[field]: fieldData}, {[field]: fieldConfig});
        if (fieldErrors[field]) {
          validationErrors[field] = fieldErrors[field];
        }
      }
    });

    if (!supervisorRef.current || !supervisorRef.current.querySelector('textarea').value) {
      validationErrors.supervisor = 'Это поле обязательно для заполнения.';
    }
    console.log('Validation Errors:', validationErrors);
    setErrors(validationErrors);
    return validationErrors;
  }, [data, validatorConfig]);

  const [serversTouched, setServersTouched] = useState(false);
  const [serversError, setServersError] = useState('');
  const [servers, setServers] = useState([]);
  const [selectedServers, setSelectedServers] = useState([]);

  useEffect(() => {
    fetchServers();
  }, []);
  const fetchServers = async () => {
    try {
      const response = await fetch(`${backendUrl}/course/getServerList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      console.log(data);
      setServers(data.result.servers || []);
    } catch (error) {
      console.error('Error loading servers:', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setServersTouched(true);
    const updatedSelectedServers = selectedServers.includes(id) ?
      selectedServers.filter((serverId) => serverId !== id) :
      [...selectedServers, id];
    setSelectedServers(updatedSelectedServers);

    if (updatedSelectedServers.length === 0) {
      setServersError('Выберите хотя бы один сервер');
    } else {
      setServersError('');
    }
  };

  const checkIfAtLeastOneChecked = () => {
    const checkboxes = document.querySelectorAll('.server-list input[type="checkbox"]');
    return Array.from(checkboxes).some((checkbox) => checkbox.checked);
  };

  const clearSupervisorError = () => {
    setSupervisorError('');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    const isValid = Object.keys(validationErrors).length === 0;
    const isServersChecked = checkIfAtLeastOneChecked();
    if (!isServersChecked) {
      setServersError('Выберите хотя бы один сервер.');
      setServersTouched(true);
    } else {
      setServersError('');
    }
    if (homeworks.length === 0) {
      setTasksError('Добавьте хотя бы одно задание.');
    } else {
      setTasksError('');
    }
    setSupervisorError(validationErrors.supervisor || '');
    if (!isValid || !isServersChecked || homeworks.length === 0) {
      if (validationErrors.name) {
        nameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        nameRef.current.focus();
      } else if (validationErrors.shortName) {
        shortNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        shortNameRef.current.focus();
      } else if (validationErrors.supervisor && supervisorRef.current) {
        supervisorRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      } else if (validationErrors.spreadsheetUrl) {
        spreadsheetUrlRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        spreadsheetUrlRef.current.focus();
      } else if (validationErrors.sheetUrlToReviewQueue) {
        spreadsheetUrlRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
        spreadsheetUrlRef.current.focus();
      } else if (tasksError) {
        tasksRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      } else if (serversError) {
        serversRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      return;
    }

    const requestBody = {
      name: data.name,
      shortname: data.shortName,
      year: Number(data.year),
      isAutumn: data.isAutumn,
      spreadsheetUrl: data.spreadsheetUrl,
      sheetId: data.sheetId,
      studentListSheetNum: parseInt(data.studentListSheetNum, 10),
      reviewQueueSheetNum: parseInt(data.reviewQueueSheetNum, 10),
      headId: selectedSupervisorId,
      seminarianIds: seminarianIds,
      assistantIds: assistantIds,
      homeworks: homeworks.map((hw) => ({
        name: hw.name,
        numOfTasks: hw.taskCount,
        needsCi: hw.ciDockerEnabled,
        tasksWithoutTesting: hw.branchesWithoutTests.split(',')
            .map((item)=> parseInt(item, 10))
            .filter((item) => !isNaN(item)),
        ciFileId: hw.ciFileId,
        dockerfileId: hw.dockerfileId,
      })),
      serverIds: Array.from(document.querySelectorAll('.server-list input[type="checkbox"]:checked'))
          .map((checkbox) => parseInt(checkbox.value, 10)),
      defaultCiFileId: data.ciFileId ? parseInt(data.ciFileId, 10) : null,
      defaultDockerfileId: data.dockerfileId ? parseInt(data.dockerfileId, 10) : null,
    };
    setFullName(requestBody.name);

    console.log('requestBody:', requestBody);

    try {
      const response = await fetch(`${backendUrl}/course/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        navigate('/course/register/success');
      } else {
        console.error('Error registering course');
      }
    } catch (error) {
      console.log('Error sending data to server:', error);
    }
  };

  const extractSheetIdFromUrl = (url) => {
    const idMatch = url.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return idMatch ? idMatch[1] : '';
  };

  const extractSheetNumFromUrl = (url) => {
    const gidMatch = url.match(/gid=(\d+)/);
    return gidMatch ? parseInt(gidMatch[1], 10) : 0;
  };

  const handleSpreadsheetUrlChange = (event) => {
    handleChange(event);
    const value = event.target.value;
    setData((prevData) => ({
      ...prevData,
      spreadsheetUrl: value,
      sheetId: extractSheetIdFromUrl(value),
      sheetNum: extractSheetNumFromUrl(value),
    }));
  };

  const handleSheetUrlToReviewQueueChange = (event) => {
    handleChange(event);
    const value = event.target.value;
    const sheetId = extractSheetIdFromUrl(value);
    const sheetNum = extractSheetNumFromUrl(value);
    errorMessageForSheetUrl('sheetUrlToReviewQueue');
    setSheetUrlToReviewQueue((prevData) => {
      return {
        ...prevData,
        sheetUrl: value,
        sheetId: sheetId,
        reviewQueueSheetNum: sheetNum,
      };
    });

    setData((prevData) => {
      return {
        ...prevData,
        reviewQueueSheetNum: sheetNum,
      };
    });
  };

  useEffect(() => {
    if (homeworks.length > 0 && tasksError) {
      setTasksError('');
    }
  }, [homeworks, tasksError]);

  const handleBlur = ({target: {name, value}}) => {
    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: 'Это поле обязательно для заполнения.',
      }));
    } else {
      const fieldErrors = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldErrors[name] || '',
      }));
    }
    if (name === 'spreadsheetUrl' || name === 'sheetUrlToReviewQueue') {
      errorMessageForSheetUrl(name);
    }
    if (name === 'shortName' || name === 'semester' || name === 'year') {
      if (data.semester && data.year && data.shortName) {
        setShowShortName(true);
      } else {
        setShowShortName(false);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(touchedFields).length > 0) {
      validate();
    }
  }, [data, touchedFields, validate]);

  const validateField = (name, value) => {
    return validator({[name]: value}, validatorConfig);
  };

  const handleSemesterChange = (event) => {
    const {name, value} = event.target;
    setData({
      ...data,
      [name]: value,
      isAutumn: value === 'Autumn',
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      semester: value === '' ? 'Выберите семестр' : '',
    }));
  };

  return (
    <div className="registration" id="registration">
      <div className="container">
        <div className="register-form-container">
          <h1 className="form-title">
            Регистрация курса
          </h1>
          <form onSubmit={handleSubmit} className="form-filds">
            <div className="form-field" ref={nameRef}>
              <div className="d-flex align-items-center">
                <h4>Полное название курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="name"
                name="name"
                value={data.name}
                onChange={handleChange}
                placeholder="Параллельные алгоритмы"
                error={errors.name}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field-two">
              <div className="form-field" ref={semesterRef}>
                <div className="d-flex align-items-center">
                  <h4>Учебный семестр</h4>
                  <span className="form-star">*</span>
                </div>
                <CustomSelect
                  id="semester"
                  name="semester"
                  value={data.semester}
                  onChange={handleSemesterChange}
                  onBlur={handleBlur}
                  options={[
                    {value: '', label: 'Выберите семестр', hidden: true},
                    {value: 'Autumn', label: 'Осень'},
                    {value: 'Spring', label: 'Весна'},
                  ]}
                />
                {errors.semester && <p className="form-error">{errors.semester}</p>}
              </div>
              <div className="form-field" ref={yearRef}>
                <div className="d-flex align-items-center">
                  <h4>Год обучения</h4>
                  <span className="form-star">*</span>
                </div>
                <TextField
                  type="text"
                  id="year"
                  name="year"
                  value={data.year}
                  onChange={handleChange}
                  placeholder={(new Date().getFullYear()).toString()}
                  error={errors.year}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="form-field" ref={shortNameRef}>
              <div className="d-flex align-items-center">
                <h4>Идентификатор курса</h4>
                <span className="form-star">*</span>
              </div>
              <TextField
                type="text"
                id="shortName"
                name="shortName"
                value={data.shortName}
                onChange={handleChange}
                placeholder="my-iDENTifier.123"
                error={errors.shortName}
                onBlur={handleBlur}
              />
            </div>
            {showShortName && (
              <div className='form-field'>
                <p className='shortName'>На GitLab будет создана группа с именем: {data.shortName}
                  -{data.year}{data.isAutumn ? 'a' : 's'}
                </p>
              </div>
            )}
            <CourseSupervisors
              ref={supervisorRef}
              setSelectedSupervisorId={setSelectedSupervisorId}
              error={supervisorError}
              onErrorClear={clearSupervisorError}
            />
            <CourseSeminarians
              selectedSupervisorId={selectedSupervisorId}
              selectedAssistants={assistantIds}
              onSelectionChange={setSeminarianIds}
            />
            <CourseAssistants
              selectedSupervisorId={selectedSupervisorId}
              selectedSeminarians={seminarianIds}
              onSelectionChange={setAssistantIds}
            />
            <div className="form-field" ref={spreadsheetUrlRef}>
              <div className="d-flex align-items-center">
                <h4>Ссылка на таблицу курса</h4>
                <span className="form-star">*</span>
              </div>
              <div className="form-indent">
                <p>Для курса необходимо создать Google таблицу,
                  в которой должно быть как минимум два листа: список студентов и очередь проверки
                  (<a href={tableExample} target="_blank" rel="noopener noreferrer">см. пример таблицы</a>).</p>
                <br/>
                <p>К таблице нужно выдать доступ на 2 email'а:</p>
                <ul>
                  <li>servers@atp-fivt.org</li>
                  <li>gsheets@automation-robot-1.iam.gserviceaccount.com</li>
                </ul>
              </div>
              <p className='link-name'>Ссылка на лист со списком студентов</p>
              <TextField
                type="text"
                id="spreadsheetUrl"
                name="spreadsheetUrl"
                value={data.spreadsheetUrl}
                onChange={handleSpreadsheetUrlChange}
                placeholder="https://docs.google.com/spreadsheets/d/example"
                error={errors.spreadsheetUrl}
                onBlur={handleBlur}
              />
              <p className='link-name'>Ссылка на лист с очередью проверки</p>
              <TextField
                type="text"
                id="sheetUrlToReviewQueue"
                name="sheetUrlToReviewQueue"
                value={sheetUrlToReviewQueue.sheetUrl}
                onChange={handleSheetUrlToReviewQueueChange}
                placeholder="https://docs.google.com/spreadsheets/d/example"
                error={errors.sheetUrlToReviewQueue}
                onBlur={handleBlur}
              />
            </div>
            <div className="form-field" ref={tasksRef}>
              <div className="d-flex align-items-center">
                <h4>Конфигурирование домашних заданий</h4>
                <span className="form-star">*</span>
              </div>
              {homeworks.length >= 0 && (
                <div className="table-container">
                  {homeworks.map((task) => (
                    <div className="table-wrapper d-flex align-items-center" key={task.id}>
                      <div className="wrapper__left">{task.name}</div>
                      <div className="wrapper__right d-flex align-items-center">
                        <button type="button" className="preview edit"
                          onClick={() => handlePreviewTask(task.id)}>
                          <img src={preview} alt="Предпросмотр"/>
                        </button>
                        <button type="button" className="edit"
                          onClick={() => handleEditTask(task.id)}>
                          <img src={edit} alt="Редактировать"/>
                        </button>
                        <button type="button" className="delete"
                          onClick={() => handleDeleteTask(task.id)}>
                          <img src={del} alt="Удалить"/>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <button type="button" className="form-plus form-modal"
                onClick={() => {
                  setEditIndex(null);
                  setModalActive(true);
                }}>
                <img src={plus} alt="Plus"/>
              </button>
              {tasksError && <p className="form-error">{tasksError}</p>}
              <Modal
                active={modalActive}
                setActive={closeModal}
                onAdd={handleAddTask}
                onSave={(taskData) => {
                  if (editIndex !== null) {
                    handleSaveTask(editIndex, taskData);
                  } else {
                    handleAddTask(taskData);
                  }
                }}
                taskToEdit={editIndex !== null ? homeworks[editIndex] : null}
                existingNames={homeworks.map((task) => task.name)}
                previewMode={previewMode}
                setPreviewMode={setPreviewMode}
              />
            </div>
            <div className="form-field form-margin" ref={serversRef}>
              <div className="d-flex align-items-center">
                <h4>Необходимые сервера</h4>
                <span className="form-star">*</span>
              </div>
              <p> Отметьте галочкой нужные для курса сервера.
                Характеристики каждого<br/> сервера - в кружочке с вопросом.
              </p>
              <ul className="server-list">
                {servers.map((server) => {
                  const os = `ОS: ${server.os || 'не указано'}`;
                  const gpu = `GPU: ${server.gpu ? 'есть' : 'нет'}`;
                  const serverDescription = `Описание: ${server.description || 'не указано'}`;
                  const fullDescription = `${os}\n${gpu}\n${serverDescription}`;

                  return (
                    <li key={server.id} className="server-item d-flex align-items-center">
                      <label className="server-name">
                        <input
                          type="checkbox"
                          value={server.id}
                          onChange={() => handleCheckboxChange(server.id)}
                          checked={selectedServers.includes(server.id)}
                        />
                      </label>
                      <p>{server.name}</p>
                      <span
                        className=
                          {modalActive ? 'server-characteristics none' :
                            'server-characteristics'}
                        data-description={fullDescription}
                        style={{
                          marginLeft: '10px',
                        }}
                      >
                      ?
                      </span>
                    </li>
                  );
                })}
              </ul>
              {serversTouched && serversError && <p className="form-error">{serversError}</p>}
            </div>
            <div className="form-field">
              <div>
                <h4>Специализированный CI и Dockerfile</h4>
              </div>
              <div className="form-indent">
                <ul className="no-flex">
                  <li>
                    Если нужен CI, то добавьте .gitlab-ci.yml файл, который будет использоваться
                    по умолчанию в каждом репозитории с домашним <br/> заданием;
                  </li>
                  <li>
                    Если CI нужно запускать в специальном docker контейнере, то добавьте Dockerfile.
                  </li>
                </ul>
              </div>
              <AttachmentUpload
                ciFileId={data.ciFileId}
                ciFileName={data.ciFileName}
                dockerfileId={data.dockerfileId}
                dockerFileName={data.dockerFileName}
                onUploadSuccess={(fileType, file) => {
                  if (fileType === 'CI') {
                    setData((prevData) => ({
                      ...prevData,
                      ciFileId: file.id,
                      ciFileName: file.filename,
                    }));
                  } else if (fileType === 'Dockerfile') {
                    setData((prevData) => ({
                      ...prevData,
                      dockerfileId: file.id,
                      dockerFileName: file.filename,
                    }));
                  }
                }}
                onRemoveSuccess={(fileType) => {
                  if (fileType === 'CI') {
                    setData((prevData) => ({
                      ...prevData,
                      ciFileId: null,
                      ciFileName: null,
                    }));
                  } else if (fileType === 'Dockerfile') {
                    setData((prevData) => ({
                      ...prevData,
                      dockerfileId: null,
                      dockerFileName: null,
                    }));
                  }
                }}
              />
            </div>
            <button type="submit" className="form-register">
              Зарегистрировать курс
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CourseRegistration;

import React, {useContext} from 'react';

import {Link} from 'react-router-dom';
import './mainPage.css';
import inner from '../../assets/logo-inner.svg';
import outside from '../../assets/logo-outside-dark.svg';
import {AuthContext} from '../../context/AuthContext';

const MainPage = () => {
  const {userData} = useContext(AuthContext);

  return (
    <div className={`main-page ${userData && userData.firstName ? 'content-height' : 'no-content'}`} id="main-page">
      <div className="container">
        <div className="main">
          <div className="img">
            <img src={outside} className="main__img" alt="logo-outside"/>
            <img src={inner} className="main__img__inner" alt="logo-inner"/>
          </div>
          <h1 className="main__title"> Studoko </h1>
          <p className="main__text">
            Проект кафедры АТП по централизации учебной работы
          </p>
          <div className="main__center">
            {userData && userData.firstName && userData.approved &&
              (userData.teacherRole === 'ADMIN' || userData.teacherRole === 'LECTURER') && (
              <>
                <Link to="/course/register" className="main__button">
                  Регистрация нового курса
                </Link>
                {userData.teacherRole === 'ADMIN' && (
                  <Link to="/config/download" className="main__button">
                    Скачать конфигурационные файлы
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;

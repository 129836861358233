import React, {useContext} from 'react';

import {Link} from 'react-router-dom';
import './header.css';
import logo from '../../assets/logo-dark.svg';
import beta from '../../assets/logo-beta.svg';
import {AuthContext, UserData} from '../../context/AuthContext';
import {backendUrl} from '../../constant/AppConstant';

const Header = () => {
  const {userData, setUserData} = useContext(AuthContext);
  const handleLogout = async () => {
    try {
      const response = await fetch(`${backendUrl}/session/clear`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        setUserData(new UserData());
        window.location.href = '/';
      } else {
        throw new Error('Failed to logout');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="header" id="header">
      <div className="container">
        <div className="navbar d-flex align-items-center
                        justify-content-between">
          <Link to="/" className="navbar__img d-flex align-items-center">
            <div>
              <img className="studoko__img" src={logo} alt="Studoko img"/>
            </div>
            <img className="navbar__name" src={beta} alt="Studoko beta"/>
          </Link>
          {userData && userData.firstName ? (
            <div className="navbar__user-info d-flex align-items-center">
              <div className="user__info d-flex align-items-center">
                <span>{userData.firstName}</span>
                <span className="user__lastname">{userData.lastName}</span>
              </div>
              <button className="log-out" onClick={handleLogout}>Выйти</button>
            </div>
          ) : (
            <Link to="/login" className="log-in">Войти</Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

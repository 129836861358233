import React, {useEffect, useState} from 'react';

import './footer.css';
import logo from '../../assets/telegram.svg';
import laptop from '../../assets/thanks.svg';
import version from '../../assets/version.svg';
import ModalThanks from '../../modal/ModalThanks';
import {env, backendUrl} from '../../constant/AppConstant';
import data from '../../../package.json';

const Footer = () => {
  const defaultVersion = '0.0.0';
  const defaultTag = '00000000';
  const year = new Date().getFullYear();
  const [modalActive, setModalActive] = useState(false);
  const [versionBack, setVersionBack] = useState(defaultVersion);
  const [tagBack, setTagBack] = useState(defaultTag);

  useEffect(() => {
    const fetchTagData = async () => {
      try {
        const response = await fetch(`${backendUrl}/version`, {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const tag = data.result.tag || defaultTag;
        const versionBack = data.result.release || defaultVersion;
        setVersionBack(versionBack);
        setTagBack(tag);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
        setVersionBack(defaultVersion);
        setTagBack(defaultTag);
      }
    };

    fetchTagData();
  }, []);

  const VersionHtml = () => {
    const versionFront = data.version;

    switch (env) {
      case 'DEV':
        let tagFront = defaultTag;
        try {
          tagFront = require('../../buildArgs.json').tag;
        } catch (error) {
          console.error('Error loading buildArgs.json:', error);
        }
        return (
          <div>
            <p className="ver">{versionFront} frontend: {tagFront}</p>
            <p className="ver">{versionBack} backend:  {tagBack}</p>
          </div>
        );
      case 'PROD':
        return (
          <p className="ver">{versionBack}</p>
        );
      case 'LOCAL':
        return (
          <p className="ver"> local </p>
        );
      default:
        return (
          <p className="ver"> {env} </p>
        );
    }
  };

  return (
    <div className="footer" id="footer">
      <div className="container">
        <div className="content">
          <div className="footer__navbar">
            <ul className="navbar__menu d-flex align-items-center
                          justify-content-between">
              <li className="menu__info">
                <h3> Техподдержка </h3>
                <a href="mailto:mail.google.com"> studoko.system@gmail.com </a>
              </li>
              <li className="menu__info">
                <h3> Связаться с нами </h3>
                <a href="mailto:mail.yandex.ru"> atp@phystech.edu </a>
              </li>
              <li className="menu__info">
                <h3> Сдать домашку </h3>
                <a href="https://gitlab.atp-fivt.org"> gitlab.atp-fivt.org </a>
              </li>
              <li className="menu__info">
                <h3> Вики </h3>
                <a href="http://wiki.atp-fivt.org/index.php/Учебные_курсы_кафедры"> wiki.atp-fivt.org </a>
              </li>
              <li className="menu__info">
                <h3> Вакансии </h3>
                <div className="d-flex align-items-center">
                  <img className="menu__logo" src={logo} alt="Telegram logo"/>
                  <a href="https://t.me/atp_jobs"> @atp_jobs </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="rules d-flex align-items-center">
          <div className="right__info d-flex align-items-center">
            <button className="rules__btn" onClick={() => setModalActive(!modalActive)}>
              <img className="logo" src={laptop} alt="Exit logo"/>
            </button>
            <img className="logo" src={version} alt="Version logo"/>
            <div className='rules__atp'> <VersionHtml /> </div>
          </div>
          <p className="rules__text">
            © {year} АТП МФТИ
          </p>
        </div>
      </div>
      <ModalThanks active={modalActive} setActive={setModalActive}/>
    </div>
  );
};

export default Footer;

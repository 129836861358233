import React, {forwardRef, useCallback, useEffect, useRef, useState} from 'react';
import {backendUrl} from '../../constant/AppConstant';
import {debounce} from 'lodash';

const CourseSupervisors = forwardRef(({setSelectedSupervisorId, error, onErrorClear}, ref) => {
  const [supervisorInputValue, setSupervisorInputValue] = useState('');
  const [showSupervisorOptions, setShowSupervisorOptions] = useState(false);
  const [filteredSupervisors, setFilteredSupervisors] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const dropdownSupervisorRef = useRef(null);

  const fetchSupervisors = async (searchString = '') => {
    try {
      const response = await fetch(`${backendUrl}/teacher/getLecturerList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({searchString}),
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setFilteredSupervisors(data.result.teachers || []);
        setHasFetched(true);
      } else {
        console.error('Error getting list of supervisors:', response.statusText);
      }
    } catch (error) {
      console.error('Error executing request:', error);
    }
  };

  // eslint-disable-next-line
  const debouncedFetchSupervisors = useCallback(
      debounce((searchString) => {
        fetchSupervisors(searchString);
      }, 800),
      []
  );

  const handleSupervisorInputChange = (event) => {
    const value = event.target.value;
    setSupervisorInputValue(value);
    setShowSupervisorOptions(true);

    if (value.trim()) {
      if (error) {
        onErrorClear();
      }
      debouncedFetchSupervisors(value);
    } else {
      setSelectedSupervisorId(null);
      fetchSupervisors();
    }
  };

  const handleSupervisorInputFocus = () => {
    setShowSupervisorOptions(true);
    if (!supervisorInputValue.trim()) {
      fetchSupervisors();
    }
  };

  const handleSupervisorOptionClick = (supervisor) => {
    const formattedOption =
      `${supervisor.lastName} ${supervisor.firstName} ${supervisor.patronymic || ''}\n${supervisor.email}`;
    setSupervisorInputValue(formattedOption);
    setSelectedSupervisorId(supervisor.id);
    setShowSupervisorOptions(false);
    if (onErrorClear) onErrorClear();
  };

  const handleClickOutside = (event) => {
    if (dropdownSupervisorRef.current && !dropdownSupervisorRef.current.contains(event.target)) {
      setShowSupervisorOptions(false);
    }
  };

  useEffect(() => {
    const handleMousedown = (event) => {
      if (showSupervisorOptions) {
        handleClickOutside(event);
      }
    };
    document.addEventListener('mousedown', handleMousedown);
    return () => {
      document.removeEventListener('mousedown', handleMousedown);
    };
  }, [showSupervisorOptions]);
  return (
    <div className="form-field" ref={ref}>
      <div className="d-flex align-items-center">
        <h4>Руководитель курса</h4>
        <span className="form-star">*</span>
      </div>
      <div className="dropdown" ref={dropdownSupervisorRef}>
        <textarea
          value={supervisorInputValue}
          onClick={handleSupervisorInputFocus}
          onChange={handleSupervisorInputChange}
          className="form-input form-select form-visitor"
          placeholder="Начните вводить ФИО или email"
          style={{
            whiteSpace: 'pre-line',
            resize: 'none',
            height: supervisorInputValue ? '45px' : '30px',
          }}
        />
        {showSupervisorOptions && (
          <div className="dropdown-menu">
            {filteredSupervisors.length > 0 ? (
              filteredSupervisors.map((supervisor, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleSupervisorOptionClick(supervisor)}
                >
                  <span>{supervisor.lastName} {supervisor.firstName} {supervisor.patronymic || ''}</span>
                  <span style={{display: 'block', color: 'gray', fontSize: '12px'}}>
                    {supervisor.email}
                  </span>
                </div>
              ))
            ) : (
              hasFetched && <div className="dropdown-item">Ничего не найдено</div>
            )}
          </div>
        )}
      </div>
      {error && <p className="form-error">{error}</p>}
    </div>
  );
});

export default CourseSupervisors;

import React, {useEffect, useState} from 'react';
import uploadIcon from '../../assets/upload.svg';
import ciIcon from '../../assets/ci.svg';
import dockerIcon from '../../assets/docker.svg';
import deleteIcon from '../../assets/delete.svg';
import './AttachmentUpload.css';
import useAttachmentUpload from './useAttachmentUpload';
import calculateMd5 from '../../utils/calculateMd5';

const AttachmentUpload = ({ciFileId, ciFileName, dockerfileId, dockerFileName, onUploadSuccess, onRemoveSuccess}) => {
  const [ciFile, setCiFile] = useState(null);
  const [dockerFile, setDockerFile] = useState(null);
  const {uploadAttachment, removeAttachment} = useAttachmentUpload();
  const [ciErrorMessage, setCiErrorMessage] = useState(null);
  const [dockerErrorMessage, setDockerErrorMessage] = useState(null);

  useEffect(() => {
    if (ciFileId && ciFileName) {
      setCiFile({id: ciFileId, filename: ciFileName});
    } else {
      setCiFile(null);
    }
  }, [ciFileId, ciFileName]);

  useEffect(() => {
    if (dockerfileId && dockerFileName) {
      setDockerFile({id: dockerfileId, filename: dockerFileName});
    } else {
      setDockerFile(null);
    }
  }, [dockerfileId, dockerFileName]);
  const MAX_FILE_SIZE = 100 * 1024;
  const handleFileChange = async (event, fileType) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        if (fileType === 'CI') {
          setCiErrorMessage('Файл слишком большой. Лимит: 100 КБ');
        } else if (fileType === 'Dockerfile') {
          setDockerErrorMessage('Файл слишком большой. Лимит: 100 КБ');
        }
        return;
      }
      try {
        if (fileType === 'CI') {
          setCiErrorMessage(null);
        } else if (fileType === 'Dockerfile') {
          setDockerErrorMessage(null);
        }
        const md5Checksum = await calculateMd5(selectedFile);
        const response = await uploadAttachment(selectedFile, md5Checksum);
        const fileData = {
          id: response.id,
          filename: selectedFile.name,
        };
        if (fileType === 'CI') {
          setCiFile(fileData);
        } else if (fileType === 'Dockerfile') {
          setDockerFile(fileData);
        }
        if (onUploadSuccess) {
          onUploadSuccess(fileType, fileData);
        }
      } catch (error) {
        console.error('Error while uploading file:', error);
      }
    }
  };

  const handleRemove = async (fileType) => {
    const fileToRemove = fileType === 'CI' ? ciFile : dockerFile;
    if (fileToRemove && fileToRemove.id) {
      try {
        await removeAttachment(fileToRemove.id);
        if (fileType === 'CI') {
          setCiFile(null);
        } else if (fileType === 'Dockerfile') {
          setDockerFile(null);
        }

        if (onRemoveSuccess) {
          onRemoveSuccess(fileType);
        }
      } catch (error) {
        console.error('Error when deleting file:', error);
      }
    }
  };
  return (
    <div className="attachmentUpload">
      <div className="attachment d-flex align-items-center">
        <div className="attachment__left d-flex align-items-center">
          {ciFile ? (
            <>
              <button
                type="button"
                onClick={() => handleRemove('CI')}
                className="delete"
              >
                <img src={deleteIcon} alt="Удалить CI"/>
              </button>
              <img className="attachment__img" src={ciIcon} alt="CI"/>
              <span className="success-upload">{ciFile.filename}</span>
            </>
          ) : (
            <div className="flex-direction d-flex align-items-center">
              <label className="uploadButton">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'CI')}
                  style={{display: 'none'}}
                />
                <img src={uploadIcon} alt="Загрузить CI"/>
              </label>
              <img className="attachment__img" src={ciIcon} alt="CI"/>
              <span>CI</span>
            </div>
          )}
        </div>
        <div className="attachment__right d-flex align-items-center">
          {dockerFile ? (
              <>
                <span className="success-upload">{dockerFile.filename}</span>
                <img className="attachment__img" src={dockerIcon} alt="Dockerfile"/>
                <button
                  type="button"
                  onClick={() => handleRemove('Dockerfile')}
                  className="delete"
                >
                  <img src={deleteIcon} alt="Удалить Dockerfile"/>
                </button>
              </>
          ) : (
            <div className="flex-direction d-flex align-items-center">
              <span>Dockerfile</span>
              <img className="attachment__img" src={dockerIcon} alt="Dockerfile"/>
              <label className="uploadButton">
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e, 'Dockerfile')}
                  style={{display: 'none'}}
                />
                <img src={uploadIcon} alt="Загрузить Dockerfile"/>
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        {ciErrorMessage && (
          <div className="error-message error-ci">
            <span>{ciErrorMessage}</span>
          </div>
        )}
        {dockerErrorMessage && (
          <div className="error-message error-docker">
            <span>{dockerErrorMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachmentUpload;

import React, {useState} from 'react';
import './customSelect.css';

const CustomSelect = ({id, name, value, onChange, onBlur, options}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value || '');

  const handleSelectClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (optionValue) => {
    setSelectedValue(optionValue);
    setIsOpen(false);
    if (onChange) {
      onChange({target: {name: name, value: optionValue || ''}});
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      setIsOpen(false);
      onBlur({
        target: {
          name: name,
          value: selectedValue || '',
        },
      });
    }
  };

  return (
    <div className="custom-select-container" onBlur={handleBlur} tabIndex="0">
      <div
        id={id}
        className={`custom-select ${selectedValue === '' ? 'empty-select' : ''}`}
        onClick={handleSelectClick}
      >
        {selectedValue === '' ? options.find((opt) => opt.hidden)?.label || 'Выберите семестр' :
        options.find((opt) => opt.value === selectedValue)?.label}
      </div>
      {isOpen && (
        <ul className="custom-select-options">
          {options
              .filter((option) => !option.hidden)
              .map((option) => (
                <li
                  key={option.value}
                  className="custom-select-option"
                  onClick={() => handleOptionClick(option.value)}
                >
                  {option.label}
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;

import React, {useState, useEffect} from 'react';
import './modalhomework.css';
import AttachmentUpload from '../components/attachmentUpload/AttachmentUpload';

const Modal = ({active, setActive, onSave, taskToEdit, existingNames, previewMode, setPreviewMode}) => {
  const [data, setData] = useState({
    supervisor: '',
    taskCount: '',
    unknownTaskCount: false,
    systemTest: false,
    ciDockerEnabled: false,
    needsCi: false,
    ciFileName: null,
    dockerFileName: null,
    branchesWithoutTests: '',
    ciFileId: null,
    dockerfileId: null,
    tasks: [],
  });

  const [nameError, setNameError] = useState('');
  const [taskCountError, setTaskCountError] = useState('');
  const [branchesError, setBranchesError] = useState('');

  useEffect(() => {
    if (active && taskToEdit) {
      console.log('taskToEdit:', taskToEdit);
      const hasCiFile =
        taskToEdit.ciFileId ||
        (taskToEdit.tasks && taskToEdit.tasks[0]?.ciFileId);
      const hasDockerFile =
        taskToEdit.dockerfileId ||
        (taskToEdit.tasks && taskToEdit.tasks[0]?.dockerfileId);

      const ciDockerEnabled = !!(hasCiFile || hasDockerFile);
      setData({
        supervisor: taskToEdit.name || '',
        taskCount: taskToEdit.unknownTaskCount ? '' : taskToEdit.taskCount?.toString() || '',
        unknownTaskCount: taskToEdit.unknownTaskCount || false,
        systemTest: taskToEdit.systemTest || false,
        ciDockerEnabled: ciDockerEnabled || false,
        branchesWithoutTests: taskToEdit.branchesWithoutTests || '',
        ciFileId:
          taskToEdit.ciFileId ||
          (taskToEdit.tasks && taskToEdit.tasks[0]?.ciFileId) ||
          null,
        ciFileName: taskToEdit.ciFileName || null,
        dockerfileId:
          taskToEdit.dockerfileId ||
          (taskToEdit.tasks && taskToEdit.tasks[0]?.dockerfileId) ||
          null,
        dockerFileName: taskToEdit.dockerFileName || null,
        tasks: taskToEdit.tasks || [],
      });
    } else if (!active) {
      setData({
        supervisor: '',
        taskCount: '',
        unknownTaskCount: false,
        systemTest: false,
        ciDockerEnabled: false,
        branchesWithoutTests: '',
        ciFileId: null,
        ciFileName: null,
        dockerfileId: null,
        dockerFileName: null,
        tasks: [],
      });
    }
    if (active) {
      setNameError('');
      setTaskCountError('');
      setBranchesError('');
    }
  }, [taskToEdit, active]);

  useEffect(() => {
    if (!data.systemTest || data.unknownTaskCount) {
      setData((prevData) => ({
        ...prevData,
        branchesWithoutTests: '',
      }));
    }
  }, [data.systemTest, data.unknownTaskCount]);

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target;
    const updatedValue = type === 'checkbox' ? checked : value.toString();

    if (name === 'branchesWithoutTests') {
      validateBranches(updatedValue);
    }
    const updatedData = {
      ...data,
      [name]: updatedValue,
    };
    if (name === 'unknownTaskCount' && checked) {
      updatedData.taskCount = '';
      updatedData.systemTest = false;
      updatedData.branchesWithoutTests = '';
      setTaskCountError('');
      setBranchesError('');
    } else if (name === 'systemTest' && !checked) {
      updatedData.branchesWithoutTests = '-';
      setBranchesError('');
    } else if (name === 'systemTest' && checked) {
      if (data.branchesWithoutTests === '-') {
        updatedData.branchesWithoutTests = '';
      }
    }

    if (name === 'ciDockerEnabled' && !checked) {
      updatedData.ciFileId = null;
      updatedData.dockerfileId = null;
    }

    setData(updatedData);

    if (name === 'supervisor') {
      validateName(value);
    } else if (name === 'taskCount') {
      if (!data.unknownTaskCount) {
        validateTaskCount(value);
      }
      if (data.systemTest && data.branchesWithoutTests.trim() !== '') {
        validateBranches(updatedData.branchesWithoutTests, value);
      }
    } else if (name === 'branchesWithoutTests') {
      if (data.systemTest) {
        validateBranches(value, updatedData.taskCount);
      }
    } else if (name === 'systemTest' && !checked) {
      setBranchesError('');
      if (data.unknownTaskCount) {
        setTaskCountError('');
      }
    } else if (name === 'unknownTaskCount') {
      if (checked) {
        setTaskCountError('');
        setData({
          ...updatedData,
          taskCount: '',
        });
      } else if (data.taskCount.trim() === '') {
        setTaskCountError('Количество задач обязательно для заполнения.');
      }
    }
  };

  const validateBranches = (branches, taskCount) => {
    if (!data.systemTest || branches.trim() === '') {
      setBranchesError('');
      return;
    }

    const cleanedBranches = branches.replace(/\s+/g, '');
    const regex = /^(\d+)(,\d+)*$/;
    const branchesArray = cleanedBranches.split(',').map((branch) => parseInt(branch, 10));

    if (!regex.test(cleanedBranches)) {
      setBranchesError('Некорректный список номеров веток. Используйте целые числа, разделенные запятой.');
      return;
    }
    const hasDuplicates = branchesArray.some(
        (branch, index) => branchesArray.indexOf(branch) !== index
    );
    if (hasDuplicates) {
      setBranchesError('Номера веток должны быть уникальными.');
      return;
    }

    if (!regex.test(cleanedBranches)) {
      setBranchesError('Некорректный список номеров веток. Используйте целые числа, разделенные запятой.');
    } else if (branchesArray.some((branch) => branch < 1 || branch > parseInt(taskCount, 10))) {
      setBranchesError('Используется некорректный номер ветки.');
    } else {
      setBranchesError('');
    }
  };

  const validateTaskCount = (count) => {
    if (data.unknownTaskCount) {
      setTaskCountError('');
      return;
    }
    if (count.trim() === '') {
      setTaskCountError('Это поле обязательно для заполнения.');
      return;
    }
    if (/[^0-9]/.test(count)) {
      setTaskCountError('Разрешены только целые числа.');
      return;
    }
    if (/^0\d+/.test(count)) {
      setTaskCountError('Количество должно быть в диапазоне от 1 до 100.');
      return;
    }
    const parsedCount = parseInt(count, 10);
    if (isNaN(parsedCount) || !Number.isInteger(parsedCount)) {
      setTaskCountError('Разрешены только целые числа.');
    } else if (parsedCount < 1 || parsedCount > 100) {
      setTaskCountError('Количество должно быть в диапазоне от 1 до 100.');
    } else {
      setTaskCountError('');
    }
  };

  const validateName = (name) => {
    const regex = /^[a-zA-Z0-9_.-]+$/;
    if (name.trim() === '') {
      setNameError('Это поле обязательно для заполнения.');
    } else if (name.length > 100) {
      setNameError('Максимальная длина поля — 100 символов.');
    } else if (!regex.test(name)) {
      setNameError('Допустимы только латинские буквы, цифры, и символы: `.`, `-` и `_`.');
    } else if (existingNames.includes(name)) {
      setNameError('Название должно быть уникальным.');
    } else {
      setNameError('');
    }
  };

  const handleSave = () => {
    let isValid = true;

    if (data.supervisor.trim() === '') {
      setNameError('Это поле обязательно для заполнения.');
      isValid = false;
    } else {
      validateName(data.supervisor);
      if (nameError) isValid = false;
    }

    if (!data.unknownTaskCount && data.taskCount.trim() === '') {
      setTaskCountError('Это поле обязательно для заполнения.');
      isValid = false;
    } else if (!data.unknownTaskCount) {
      validateTaskCount(data.taskCount);
      if (taskCountError) isValid = false;
    }

    if (data.systemTest && data.branchesWithoutTests.trim() !== '') {
      validateBranches(data.branchesWithoutTests, data.taskCount);
      if (branchesError) isValid = false;
    }

    if (!isValid) {
      console.error('Failed to complete the task: there are errors in the data.', {
        nameError,
        taskCountError,
        branchesError,
      });
      return;
    }

    const hasCiFile = data.ciFileId !== null;
    const hasDockerFile = data.dockerfileId !== null;
    const ciDockerEnabled = hasCiFile || hasDockerFile;

    const homeworkData = {
      name: data.supervisor,
      taskCount: data.taskCount ? parseInt(data.taskCount, 10) : null,
      unknownTaskCount: data.unknownTaskCount,
      ciDockerEnabled: ciDockerEnabled,
      systemTest: data.systemTest,
      branchesWithoutTests: data.branchesWithoutTests,
      ciFileName: data.ciFileName || null,
      ciFileId: data.ciFileId || null,
      dockerfileId: data.dockerfileId || null,
      dockerFileName: data.dockerFileName || null,
      needsCi: data.ciDockerEnabled,
    };

    onSave(homeworkData);

    setData({
      supervisor: '',
      taskCount: '',
      unknownTaskCount: false,
      systemTest: false,
      ciDockerEnabled: false,
      ciFileName: null,
      dockerFileName: null,
      branchesWithoutTests: '',
      ciFileId: null,
      dockerfileId: null,
    });
    setActive(false);
  };

  const handleBlur = (e) => {
    const {name, value} = e.target;

    if (value.trim() === '') {
      switch (name) {
        case 'supervisor':
          setNameError('Это поле обязательно для заполнения.');
          break;
        case 'taskCount':
          if (!data.unknownTaskCount) {
            setTaskCountError('Это поле обязательно для заполнения.');
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className=
      {active ? `modal active` : 'modal'}
    onClick={setActive}>
      <div className={`modal__content ${data.systemTest && !data.unknownTaskCount ? 'extra-height' : ''} ${
        nameError || taskCountError || branchesError ? 'small' : ''
      }`}
      onClick={(e) => e.stopPropagation()}>
        <div className="modal-form">
          <h4 className="modal__title">
            {taskToEdit ? 'Изменить домашнее задание' : 'Новое домашнее задание'}
          </h4>
          <div className="form-field">
            <div className="d-flex align-items-center">
              <h4>Название</h4>
              <span className="form-star">*</span>
            </div>
            <input
              className={`modal-input ${nameError ? 'error' : ''}`}
              type="text"
              name="supervisor"
              value={data.supervisor}
              onChange={handleChange}
              placeholder="mapreduce"
              onBlur={handleBlur}
              disabled={previewMode}
            />
            {nameError && <p className="error-message"
              style={{color: 'red', fontSize: '14px'}}>{nameError}</p>}
          </div>
          <div className="form-field">
            <div className="d-flex align-items-center">
              <h4>Количество задач</h4>
              <span className="form-star">*</span>
            </div>
            <input
              className={`modal-input ${taskCountError ? 'error' : ''}`}
              type="text"
              name="taskCount"
              value={data.taskCount}
              onChange={handleChange}
              placeholder="Например, 5"
              disabled={previewMode || data.unknownTaskCount}
              onBlur={handleBlur}
            />
            {taskCountError &&
              <p className="error-message" style={{color: 'red', fontSize: '14px'}}>
                {taskCountError}
              </p>
            }
            <div className="checkbox d-flex align-items-center">
              <input
                id="unknownTaskCount"
                className="checkbox__input"
                type="checkbox"
                name="unknownTaskCount"
                checked={data.unknownTaskCount}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={previewMode}
              />
              <label htmlFor="unknownTaskCount"
                className="checkbox__text">
                Я не знаю количество задач
              </label>
            </div>
            <div className="system-test d-flex align-items-center">
              <div className="d-flex align-items-center">
                <h4>Система тестирования:</h4>
              </div>
              <div className="toggle">
                <input
                  className="toggle__input"
                  type="checkbox"
                  id="check"
                  name="systemTest"
                  checked={data.systemTest}
                  disabled={previewMode}
                  onChange={(e) => {
                    handleChange(e);
                    if (!e.target.checked || data.unknownTaskCount) {
                      setData((prevData) => ({
                        ...prevData,
                        branchesWithoutTests: '',
                      }));
                    }
                  }}
                />
                <label htmlFor="check" className="toggle__button"></label>
              </div>
            </div>
            {data.systemTest && !data.unknownTaskCount && (
              <div className="form-field">
                <div className="d-flex align-items-center">
                  <h4>Номера веток без тестов</h4>
                </div>
                <input
                  className={`modal-input ${branchesError ? 'error' : ''}`}
                  type="text"
                  name="branchesWithoutTests"
                  value={data.branchesWithoutTests}
                  disabled={previewMode}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.checked) {
                      setData((prevData) => ({
                        ...prevData,
                        branchesWithoutTests: '',
                        taskCount: '',
                      }));
                    }
                  }}
                  placeholder="Начните вводить 1, 2, 3"
                  onBlur={handleBlur}
                />
                {branchesError &&
                  <p className="error-message" style={{color: 'red', fontSize: '14px'}}>
                    {branchesError}
                  </p>
                }
              </div>
            )}
            <div className="system-test d-flex align-items-center">
              <div className="d-flex align-items-center">
                <h4>Специализированный CI и Dockerfile:</h4>
              </div>
              <div className="toggle">
                <input
                  className="toggle__input"
                  type="checkbox"
                  id="ciDockerEnabled"
                  name="ciDockerEnabled"
                  checked={data.ciDockerEnabled}
                  disabled={previewMode}
                  onChange={handleChange}
                />
                <label htmlFor="ciDockerEnabled" className="toggle__button"></label>
              </div>
            </div>
            {data.ciDockerEnabled && (
              <div className="form-wrapper">
                <ul className="no-margin">
                  <li>
                    Если нужен CI, то добавьте .gitlab-ci.yml файл, который
                    будет использоваться в репозитории для текущего домашнего задания;
                  </li>
                  <li>
                    Если CI нужно запускать в специальном docker
                    контейнере, то добавьте Dockerfile.
                  </li>
                </ul>
                <div className="form-note">
                  <span>Примечание:</span>
                  <p>
                    Загруженные в этом поле файлы будут заменять для текущего домашнего задания
                    глобальные .gitlab-ci.yml и Dockerfile, если они были загружены для курса.
                  </p>
                </div>
                <div className="form-configure d-flex align-items-center">
                  <AttachmentUpload
                    ciFileId={data.ciFileId}
                    ciFileName={data.ciFileName}
                    dockerfileId={data.dockerfileId}
                    dockerFileName={data.dockerFileName}
                    onUploadSuccess={(fileType, file) => {
                      setData((prevData) => {
                        const newData = {...prevData};
                        if (fileType === 'CI') {
                          newData.ciFileId = file.id;
                          newData.ciFileName = file.filename;
                        } else if (fileType === 'Dockerfile') {
                          newData.dockerfileId = file.id;
                          newData.dockerFileName = file.filename;
                        }
                        newData.ciDockerEnabled = newData.ciFileId || newData.dockerfileId ? true : false;
                        return newData;
                      });
                    }}
                    onRemoveSuccess={(fileType) => {
                      setData((prevData) => {
                        const newData = {...prevData};
                        if (fileType === 'CI') {
                          newData.ciFileId = null;
                          newData.ciFileName = null;
                        } else if (fileType === 'Dockerfile') {
                          newData.dockerfileId = null;
                          newData.dockerFileName = null;
                        }
                        newData.ciDockerEnabled = newData.ciFileId || newData.dockerfileId ? true : false;
                        return newData;
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          {previewMode ? (
            <div className="modal-buttons d-flex align-items-center">
              <button
                type="button"
                className="modal__button modal__left"
                onClick={() => {
                  setPreviewMode(false);
                }}
              >
                Редактировать
              </button>
              <button
                type="button"
                className="modal__button close"
                onClick={() => setActive(false)}
              >
                Закрыть
              </button>
            </div>
          ) : (
            <div className="modal-add">
              <button
                type="button"
                className="modal__button"
                onClick={handleSave}
              >
                {taskToEdit ? 'Сохранить' : 'Добавить'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/assets/select.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select-container {
    position: relative;
    width: 200px;
    display: inline-block;
    font-family: 'Lato', system-ui;
}

.custom-select {
    width: 100%;
    min-width: 220px;
    padding: 9px 16px;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    color: #363636;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    background: white url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat right 10px center;
    background-size: 12px;
    transition: border-color 0.3s;
}

.custom-select.empty-select {
    color: #aaa;
}

.custom-select-options {
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    border: 1px solid #E4E4E4;
    border-radius: 8px;
    margin-top: 2px;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
    min-width: 218px;
}

.custom-select-option {
    padding: 10px;
    color: #363636;
    background-color: white;
}

.custom-select-option:hover {
    background: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/select/customSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,eAAe;IACf,aAAa;IACb,qFAA2E;IAC3E,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".custom-select-container {\n    position: relative;\n    width: 200px;\n    display: inline-block;\n    font-family: 'Lato', system-ui;\n}\n\n.custom-select {\n    width: 100%;\n    min-width: 220px;\n    padding: 9px 16px;\n    border: 1px solid #E4E4E4;\n    border-radius: 8px;\n    color: #363636;\n    font-size: 16px;\n    cursor: pointer;\n    outline: none;\n    background: white url('/src/assets/select.svg') no-repeat right 10px center;\n    background-size: 12px;\n    transition: border-color 0.3s;\n}\n\n.custom-select.empty-select {\n    color: #aaa;\n}\n\n.custom-select-options {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    background: #fff;\n    border: 1px solid #E4E4E4;\n    border-radius: 8px;\n    margin-top: 2px;\n    padding: 0;\n    max-height: 150px;\n    overflow-y: auto;\n    z-index: 999;\n    width: 100%;\n    min-width: 218px;\n}\n\n.custom-select-option {\n    padding: 10px;\n    color: #363636;\n    background-color: white;\n}\n\n.custom-select-option:hover {\n    background: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
